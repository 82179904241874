<template>
  <section>
    <b-row fluid>
      <b-col lg="4" cols="4" sm="4">
        <b-card-actions
            class="cari-liste-card"
            ref="stok-listesi"
            @refresh="refreshStop('stok-listesi')"
        >
          <b-row class="mt-50">
            <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
              <b-col cols="12">
                <h4 class="text-white d-inline-block card-title">Stok Aksiyon Listesi</h4>
              </b-col>
            </div>
          </b-row>
          <b-row class="custom-table-top">
            <app-timeline class="mt-4 stok-timeline">
              <app-timeline-item
                  title="Goal Achieved"
                  subtitle="All milestones are completed"
                  time="few minutes ago"
                  variant="success"
              />
              <app-timeline-item
                  title="Last milestone remain"
                  subtitle="You are just one step away from your goal"
                  time="3 minutes ago"
                  variant="info"
              />
              <app-timeline-item
                  title="Your are running low on time"
                  subtitle="Only 30 minutes left to finish milestone"
                  time="21 minutes ago"
                  variant="warning"
              />
              <app-timeline-item
                  title="Client Meeting"
                  subtitle="New event has been added to your schedule"
                  time="36 minutes ago"
              />
              <app-timeline-item
                  title="Product Design"
                  subtitle="Product design added in workflow"
                  time="1 hour ago"
                  variant="secondary"
              />
            </app-timeline>
          </b-row>
        </b-card-actions>
      </b-col>
      <b-col lg="8" cols="8" sm="8">
        <b-card-actions
            class="cari-liste-card"
            ref="stok-listesi"
            @refresh="refreshStop('stok-listesi')"
        >
          <b-row class="mt-50">
            <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
              <b-col cols="12">
                <h4 class="text-white d-inline-block card-title">Fiyat Grafiği</h4>
              </b-col>
            </div>
          </b-row>
          <b-row class="custom-table-top">
            <b-card title="" style="width: 100%">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="mb-1 ml-1 mb-sm-0">
                  <span class="text-muted"/>
                </div>
                <div class="d-flex align-content-center mb-1 mb-sm-0">
                  <h1 class="font-weight-bolder">
                    183,382 ₺
                  </h1>
                  <div class="pt-25 ml-75">
                    <b-badge variant="light-secondary">
                      <feather-icon
                          icon="ArrowDownIcon"
                          size="12"
                          class="text-danger align-middle"
                      />
                      <span class="font-weight-bolder align-middle">24%</span>
                    </b-badge>
                  </div>
                </div>
              </div>
              <app-echart-line :option-data="option"/>
            </b-card>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-modal
        id="edit"
        ok-only
        hide-footer
        hide-header
        centered
        size="lg"
        header-bg-variant="white"
        no-close-on-esc
        no-close-on-backdrop
    >
      <b-row>
        <b-col cols="12">
          <h3 class="d-inline-block">
            <b-badge variant="success" class="modalBadge">
              {{ editName }}
            </b-badge>
          </h3>
          <h5 class="edit-name">Adlı Stok Düzenleniyor</h5>
        </b-col>
        <b-col cols="6" class="mt-1">
          <b-form-group label="Stok Adı" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="editItem.stokAdi" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="6" class="mt-1">
          <b-form-group label="Stok Grubu" label-for="basicInput">
            <model-list-select
                v-model="editItem.stokGrupID"
                :list="groupGet"
                option-value="stokGrupID"
                option-text="grupAdi"
                placeholder="Stok Grubunu Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="Alış Fiyat" label-for="basicInput">
            <b-form-input type="number" autocomplete="off" v-model="editItem.alisFiyat" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="Birim Fiyatı" label-for="basicInput">
            <b-form-input type="number" autocomplete="off" v-model="editItem.birimFiyat" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="KDV" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="editItem.kdv" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group label="Birim" label-for="basicInput">
            <b-form-select v-model="editItem.birim" :options="birimOptions" placeholder="Stok Grubunu Seçiniz"/>
          </b-form-group>
        </b-col>
        <b-col cols="4" class="mt-1">
          <b-form-group label="Barkod" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="editItem.barkod" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="mt-1">
          <b-form-group :label="editItem.birim" label-for="basicInput">
            <b-form-input autocomplete="off" v-model="editItem.adet" id="basicInput"/>
          </b-form-group>
        </b-col>
        <b-col cols="2" class="mt-1">
          <b-form-group id="stoksuz-satis-label" label="Stoksuz Satış" label-for="basicInput">
            <b-form-checkbox
                checked="true"
                class="custom-control-primary"
                name="check-button"
                switch
                autocomplete="off"
                v-model="editItem.stoksuzSatis"
            >
              <span class="switch-icon-left">
                <font-awesome-icon icon="fa-solid fa-check"/>
              </span>
              <span class="switch-icon-right">
                    <font-awesome-icon icon="fa-solid fa-xmark"/>
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
              @click="update"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-50"
              :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important',border:'none'}"
          >
            Kaydet
          </b-button>
          <b-button
              @click="editHide"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
          >
            Vazgeç
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-sidebar
        id="add-stok-grup"
        :visible="isNewGroup"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        right
        @change="(val) => $emit('update:is-add-stok-grup-active', val)"
        ref="newGrup"
    >
      <template>
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Yeni Stok Grubu Ekle
          </h5>
          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="newGroupCancel"
          />
        </div>
        <b-form @submit.prevent id="sidebar-form">
          <b-row>
            <b-col cols="12">
              <b-form-group
                  label="Grup Adı"
                  label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="vi-first-name"
                      v-model="grupName"
                      autocomplete="off"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled="!grupName"
                  @click="grupSave('kayit')"
                  :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important',border:'none'}"
              >
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                Kaydet
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="newGroupCancel"
              >
                Vazgeç
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BCard,
  BSidebar,
  BModal,
  BFormSelect,
  VBTooltip,
  BBadge,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'
import store from '@/store'
import _ from 'lodash'
import axios from 'axios'
import { useWindowSize } from '@vueuse/core'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    AppEchartLine,
    BCard,
    BSidebar,
    BModal,
    BFormSelect,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardActions,
    ModelListSelect,
  },
  data() {
    return {
      option: {
        xAxisData: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        series: ['290', '200', '210', '190', '250', '220', '80', '90', '200', '150', '160', '100', '140', '100', '30'],
      },
      oldEditGrupID: '',
      id: this.$route.params.id,
      isNewGroup: false,
      grupName: '',
      grupContent: '',
      birimOptions: ['Adet', 'Koli', 'Paket', 'Kutu', 'Kg', 'Gram', 'Litre', 'Ton', 'Net Ton', 'Gross Ton', 'Yıl', 'Saat', 'Dakika', 'Saniye', 'mm', 'cm', 'Hücre Adet', 'Cift', 'Set', 'Düzine', 'Brüt Kalori'],
      degismeyenData: [],
      editName: '',
      fields: [
        {
          key: 'stokAdi',
          label: 'STOK ADI',
          sortable: true,
          thStyle: {
            width: '25%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '25%' },
        },
        {
          key: 'grupAdi',
          label: 'STOK GRUBU',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'barkod',
          label: 'BARKOD',
          sortable: true,
          thStyle: {
            width: '14%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '14%' },
        },
        {
          key: 'kdv',
          label: 'Kdv',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'birimFiyat',
          label: 'Birim Fiyat',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'alisFiyat',
          label: 'ALIŞ / SATIŞ',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'adet',
          label: 'ADET',
          sortable: true,
          thStyle: {
            width: '6%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '6%' },
        },
        {
          key: 'stokID',
          label: '',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
      ],
      addItem: {
        name: '',
        grupID: '',
        adet: '',
        birim: 'Adet',
        birimFiyat: '',
        kdv: '',
        alisFiyat: '',
        stoksuzSatis: false,
        barkod: '',
      },
      editItem: {
        stokAdi: '',
        stokGrupID: '',
        adet: '',
        birim: '',
        birimFiyat: '',
        kdv: '',
        alisFiyat: '',
        stoksuzSatis: false,
        barkod: '',
      },
    }
  },
  computed: {
    stokDetay() {
      return Object.values(store.getters.STOK_DETAY)
    },
    groupDefaultGet() {
      return Object.values(store.getters.STOK_GROUP_GET)
    },
    groupGet() {
      let grupList = []
      grupList.push(Object.values(store.getters.STOK_GROUP_GET))
      grupList[0].push({
        grupAdi: 'Yeni Stok Grubu Oluştur',
        stokGrupID: '-1',
        yeni: true,
      })
      return _.orderBy(grupList[0], ['yeni', 'grupAdi'], ['asc', 'desc'])
    },
  },
  watch: {
    addItem: {
      deep: true,
      handler(newVal) {
        if (newVal.grupID == -1) {
          this.$root.$emit('bv::toggle::collapse', 'add-stok-grup')
          this.$bvModal.hide('new')
          this.isNewGroup = true
        }
      },
    },
    editItem: {
      deep: true,
      handler(newVal) {
        if (newVal.stokGrupID == -1) {
          this.$root.$emit('bv::toggle::collapse', 'add-stok-grup')
          this.$bvModal.hide('edit')
          this.isNewGroup = true
        }
      },
    },
  },
  methods: {
    update() {
      // const guncellenenKeyler = Object.entries(this.degismeyenData)
      //     .reduce((acc, [key, value]) => {
      //       if (['stokID', 'firmaKodu'].includes(key)) return acc
      //       if (this.editItem[key] && this.editItem[key] !== value) {
      //         acc[key] = this.editItem[key]
      //       }
      //       return acc
      //     }, {})
      const item = this.editItem
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken)
      fd.append('serviceName', 'stok')
      fd.append('methodName', 'stokDuzenle')
      fd.append('islem', 'duzenle')
      fd.append('stokID', this.id)
      fd.append('updateList[stoksuzSatis]', item.stoksuzSatis == true ? 1 : 0)
      fd.append('updateList[adet]', item.adet || 0)
      fd.append('updateList[alisFiyat]', item.alisFiyat || 0)
      fd.append('updateList[barkod]', item.barkod || ' ')
      fd.append('updateList[birim]', item.birim || 'Adet')
      fd.append('updateList[birimFiyat]', item.birimFiyat || 0)
      fd.append('updateList[kdv]', item.kdv || 0)
      fd.append('updateList[stokAdi]', item.stokAdi || ' ')
      fd.append('updateList[stokGrupID]', item.stokGrupID || ' ')
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status == 200) {
              this.$bvModal.hide('edit')
              store.commit('setToken', res.data.userToken)
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
                this.getStokDetay()
              }
            } else {
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    grupSave(islem) {
      store.dispatch('stokGroupSave', {
        adi: islem === 'kayit' ? this.grupName : this.editItem.grupAdi,
        islem,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.addItem.grupID = res.data.stokGrupID
              this.editItem.stokGrupID = res.data.stokGrupID
              this.isNewStok ? this.$bvModal.show('new') : this.$bvModal.show('edit')
              this.$refs.newGrup.hide()
              this.grupName = ''
              this.grupContent = ''
              if (res.result.errMsg) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: res.result.errMsg,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            this.addItem.stokGrupID = ''
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    newGroupCancel() {
      this.grupName = ''
      if (this.isNewStok) {
        this.addItem.grupID = ''
        this.$bvModal.show('new')
      } else {
        this.editItem.stokGrupID = this.oldEditGrupID
        this.$bvModal.show('edit')
      }
      this.isNewGroup = false
    },
    add() {
      store.dispatch('stokSave', {
        stokAdi: this.addItem.name,
        stokGrupID: this.addItem.grupID,
        adet: this.addItem.adet,
        birim: this.addItem.birim,
        birimFiyat: this.addItem.birimFiyat.replace(',', '.'),
        kdv: this.addItem.kdv,
        alisFiyat: this.addItem.alisFiyat.replace(',', '.'),
        stoksuzSatis: this.addItem.stoksuzSatis,
        barkod: this.addItem.barkod,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.isNewStok = false
              this.newReset()
              this.$bvModal.hide('new')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: res.result.errMsg,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    editHide() {
      this.$bvModal.hide('edit')
      const t = this
      t.editName = ''
      t.editItem.stokAdi = ''
      t.editItem.stokGrupID = ''
      t.editItem.adet = ''
      t.editItem.birim = 'Adet'
      t.editItem.birimFiyat = ''
      t.editItem.kdv = ''
      t.editItem.alisFiyat = ''
      t.editItem.stoksuzSatis = false
      t.editItem.barkod = ''
    },
    newReset() {
      this.addItem.name = ''
      this.addItem.grupID = ''
      this.addItem.adet = ''
      this.addItem.birim = 'Adet'
      this.addItem.birimFiyat = ''
      this.addItem.kdv = ''
      this.addItem.alisFiyat = ''
      this.addItem.stoksuzSatis = false
      this.addItem.barkod = ''
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'stok-listesi'].showLoading = false
      }, 1000)
    },
    getStokDetay() {
      store.dispatch('stokDetay', this.id)
          .then(res => {
          })
    },
  },
  created() {
    this.getStokDetay()
  },
  beforeDestroy() {
    store.commit('STOK_DETAY_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>
<style>
.grup-select {
  display: inline-block !important;
  width: 15% !important;
  float: right !important;
  margin-right: 1% !important;
  margin-top: 0.3% !important;
  margin-bottom: 0.3% !important;
}

.b-sidebar .b-sidebar-body .content-sidebar-header {
  height: 4% !important;
}

.msl-searchable-list__items {
  border-top: none !important;
}

.cari-group-table .table thead th, table tbody td {
  padding: 0.4rem !important;
}

.cari-liste-card .card-header, .cari-group-add .card-header, .cari-group-edit .card-header {
  display: none !important;
}

.transfer-modal .msl-multi-select__list {
  width: 43% !important;
}
</style>
<style scoped lang="scss">
.stok-timeline {
  width: 100%;
  margin-right: 10px;

  .timeline-item {
    transition: 200ms;

    &:hover {
      transform: translateX(10px);
      transition: 200ms;
    }
  }
}

.custom-table-top {
  margin-top: 3% !important;
}

.table-top {
  margin-top: 4.5% !important;
}

.card-title {
  transform: translateY(2%) !important;
}

.card-right-button {
  /*transform: translateY(2%) !important;*/
}

#sidebar-form {
  padding: 30px !important;
}

.edit-name {
  display: inline-block;
  margin-left: 2%;
}

.search-bar {
  width: 20% !important;
}

.transfer-modal ::-webkit-scrollbar {
  width: 7px !important;
}

.transfer-modal ::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.transfer-modal ::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.transfer-modal ::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
}

.cari-group-edit .card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
  background-color: #F48484 !important;
}

.item-group-tur {
  position: absolute !important;
  margin-left: 3%;
}

.list-group-item {
  border: none !important;
  cursor: pointer !important;
  transition: 200ms;
}

.list-group-item:hover, span p {
  transition: 200ms;
  color: black !important;
}

/*@media only screen and (max-width: 1000px) {*/
/*  .search-bar {*/
/*    !*width: 20% !important;*!*/
/*    display: none !important;*/
/*  }*/
/*}*/

.search-input {
  border-color: #c3baba !important;
}

#filterInput:focus {
  border-color: #c3baba !important;
}

h4 {
  margin-bottom: 0 !important;
  padding: 6px;
}

.cari-group-table button {
  border: none !important;
}

.transfer-icon {
  background-color: #1F8A70 !important;
}

.trash-icon {
  background-color: #F55050 !important;
}

.transfer-modal .form-group {
  width: 100% !important;
  float: right !important;
}

.msl-multi-select {
  width: 108% !important;
}

#firma-unvan-yetkili {
  margin-top: 2px !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}

#bakiye-badge {
  font-size: 14px !important;
}

#stoksuz-satis-label {
  text-align: center;
}
</style>
